import React from "react"
import Profile from "../../components/profile";
import PrivateRoute from "../../components/privateRoute";

class Page extends React.Component {
    render() {
        return (
            <PrivateRoute path="/account/profile" component={Profile} location={this.props.location} showFooter={false} greyBackground={true} seoFields={seoFields}/>
        )
    }
}

const seoFields = {
    description: "",
    // lang,
    // meta,
    // keywords,
    title: "My Profile" 
}

export default Page