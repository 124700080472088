import React,{Suspense, lazy} from "react"
import styles from "./styles.module.css"
import { emailInvalid, phoneNumberInvalid, requiredError } from "../../common/validation";
import plus_icon from "../../images/plus.svg"
import { formatDate } from "../../common/gFunctions";
import Tooltip from "../Tooltip";
import SelectDropDown from "../selectDropDown";
const SkeletonComponents = lazy(() => import("../../shared/Skeleton"));

class FormField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dirty: false,
      propsDirty: this.props.dirty,
      focused: false
    }
  }
  
  copyText = (text) => {
    const el = document.createElement('textarea');
    el.value = text
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  handleInputChange = (event, type, item = null, fieldName="") => {
    this.setState({
      dirty: true
    });
    this.props.handleInputChange(event, type, item, fieldName);
  }

  manageEmail = () => {
    this.setState({
      dirty: true
    });
    
    this.props.manageEmail();
  }

  markPristine = () => {
    this.setState({dirty: false});
  }

  onFocus = () => {
    this.setState({focused: true});
  }

  onBlur = () => {
    this.setState({focused: false})
  }

  static getDerivedStateFromProps(props, state) {
    if (props.dirty === false && state.propsDirty === true && !props.dontResetDirty) {
      return {
        ...state,
        dirty: false,
        propsDirty: false
      }
    }

    return {
      ...state,
      propsDirty: props.dirty
    };
  }

  _applyKeyDown = (e,applyDiscountCode) => {
    if (e.key === 'Enter' && this.props.onEnter) {
        this.props.onEnter();
    }
  }

  render() {
    const {fieldName, label, sectionName, sectionValue, customValidation, 
      perLine=2, inputType="text", options = [], optional = false, 
      disabled=false, showCopy=false, sizeOverride, extraClass="", extraClassLabel="", extraClassBar="",
      hideOptionalText=false, icon=false,
      showBtn, btnText, onBtnClick, hideBtnIfError=true, msize=12,
      showTooltip=false, tooltipHeader=null, tooltipText=null, tooltipText2=null,
      requirePropsDirty = false, errorDuringFocus=false, isLoaded=true, attachToBody=false,
      disabledBlack=false, skipBootstrapClasses=false, countryCodeFieldName = null
    } = this.props;
    var size;
    switch(perLine) {
      case 1:
        size = 12;
        break;
      default:
      case 2:
        size = 6;
        break;
      case 3:
        size = 4;
    }

    size = sizeOverride || size;

    const value = sectionName ? sectionValue[sectionName][fieldName]: (fieldName ? sectionValue[fieldName] : sectionValue);

    const countryCodeValue = countryCodeFieldName ? (sectionName ? sectionValue[sectionName][countryCodeFieldName]: sectionValue[countryCodeFieldName]) : null;
    console.log('countryCodeValue',countryCodeValue)

    var labelText = label + (optional && !hideOptionalText ? ' (Optional)' : '');

    var validationClass = "";

    var showLabel = inputType !== 'checkbox';

    
    var isRequiredError = requiredError(value,inputType);

    var isValidationError = isRequiredError;

    const dirty = ((!requirePropsDirty && this.state.dirty) || this.props.dirty) && (errorDuringFocus || !this.state.focused);

    if (!optional && dirty && isRequiredError && !disabled) {
      validationClass = styles.error;
    }

    if (customValidation === 'email' && dirty && emailInvalid(value,optional) && !disabled) {
      validationClass = styles.error;
      isValidationError = true;
      labelText = 'Please enter a valid email';
    } else if (customValidation === 'phone' && dirty && phoneNumberInvalid(value,optional,countryCodeValue) && !disabled) {
      validationClass = styles.error;
      isValidationError = true;
      labelText = 'Please enter a valid number';
    } else if (fieldName == 'StateCode' && value == -1) {
      var countryValue = sectionName ? sectionValue[sectionName]['Country']: sectionValue['Country'];
      if (countryValue == 'India') {
        validationClass = styles.error;
        isValidationError = true;
        labelText = 'Please select a valid Indian state';
      }
    }

    const showBtnFinal = showBtn && onBtnClick && btnText && !(hideBtnIfError && isValidationError);

    const tooltipName = 'tooltip-'+sectionName+'-'+fieldName;
    const SkeletonVlaue = !isLoaded;
    return (
      <>
     
        <div className={["form-group", !skipBootstrapClasses && "col-md-"+size, styles.formField, validationClass, extraClass, !skipBootstrapClasses && 'col-'+msize].join(" ")}>
        
            <div className={[styles.label_text,extraClassLabel, inputType=='checkbox' ? 'd-none d-md-block' : ''].join(" ")}>
              {showLabel ? (disabled && SkeletonVlaue ? <SkeletonItem height={18} width={100}/> : labelText ) : ''}
              {
                showTooltip &&
                <>
                  <span className="qcircle" id={tooltipName} data-toggle="tooltip" data-placement="right">?</span>
                  <Tooltip text={tooltipText} text2={tooltipText2} header={tooltipHeader} target={tooltipName}/>
                </>
              }
            </div>
            <div className="input-group">
              {
                (() => {
                  if (inputType === 'dropdown') {
                    return (
                      <>
                     
                       { (disabled && SkeletonVlaue && <SkeletonItem height={28}/> )|| 
                        <SelectDropDown value={value} options={options} mprdropDown={true} disabled={disabled} callBack={(item)=>this.handleInputChange({target:{type:'select',name:fieldName,value:item.id || item}}, sectionName,true)} attachToBody={attachToBody} onFocus={this.onFocus} onBlur={this.onBlur} noBorderBottom={true}/>
                       }
                        <span className={[styles.bar,extraClassBar,this.state.focused && styles.greenBar].join(" ")}></span>
                      </>
                    )
                  }
                  else if (inputType === 'checkbox') {
                    return (
                      // <div style={{width:'100%',height:'100%'}}>
                        // <input type={inputType} name={fieldName} className={["form-control", styles.border_bottom, styles.form_input].join(" ")}  checked={value} onChange={(event)=>this.handleInputChange(event,sectionName)} disabled={disabled}/>
                        <div className={["custom-control", "custom-checkbox"].join(" ")}>    
                          {(disabled  && SkeletonVlaue && <SkeletonItem height={25} width={25}/> )||<input name={fieldName} className={["form-control", "custom-input", styles.border_bottom, styles.form_input, disabled ? "disabled" : "", disabledBlack && styles.disabledBlack].join(" ")} id={sectionName+'-'+fieldName} checked={value}  type="checkbox" onChange={(event)=>this.handleInputChange(event,sectionName)} disabled={disabled} />}
                          <label className={styles.customLabel} htmlFor={sectionName+'-'+fieldName}>{label}</label>
                      </div>
                      // </div>
                    )
                  } else if (inputType === 'date') {
                    return (
                      <>
                         {(disabled && SkeletonVlaue && <SkeletonItem height={28}/> )|| <input type={inputType} name={fieldName} className={["form-control", styles.border_bottom, styles.form_input, disabledBlack && styles.disabledBlack].join(" ")} value={value==null ? "" : formatDate(value,'yyyy-mm-dd')} onChange={(event)=>this.handleInputChange(event,sectionName)} disabled={disabled}/>}
                        { !showCopy && !showBtnFinal && <span className={[styles.bar,extraClassBar].join(' ')}></span> }
                       { icon && <button type="button" onClick={()=>{}} className={["btn", "btn-text", "value-button", styles.value_btn, "pl-0", "pr-0"].join(" ")}><img src={plus_icon} alt="" /></button>}
                      </>
                    )
                  }
                  else {
                    return (
                      <>
                      { (disabled  && SkeletonVlaue && <SkeletonItem height={28}/>)|| <input type={inputType} name={fieldName} className={["form-control", styles.border_bottom, styles.form_input, styles.iconInput, disabledBlack && styles.disabledBlack].join(" ")}  value={value==null ? "" : value} onChange={(event)=>this.handleInputChange(event,sectionName)} disabled={disabled} onKeyDown={this._applyKeyDown} onFocus={this.onFocus} onBlur={this.onBlur}/>}
                        { !showCopy && !showBtnFinal && <span className={[styles.bar,extraClassBar,this.state.focused && styles.greenBar].join(" ")}></span> }
                       {/* { icon && <button type="button" onClick={()=>{}} className={["btn", "btn-text", "value-button", styles.value_btn, "pl-0", "pr-0"].join(" ")}><img src={plus_icon} alt="" /></button>} */}
                       { icon && <button type="button" onClick={onBtnClick} className={["btn", "btn-text", "value-button", styles.value_btn, "pl-0", "pr-0", styles.plus_icon].join(" ")} disabled={disabled}><img src={icon} alt="" /></button>}
                        {/* <span className={styles.bar}></span>
                       { icon && <button type="button" onClick={()=>{this.manageEmail()}} className={["btn", "btn-text", "value-button", styles.value_btn, "pl-0", "pr-0", styles.plus_icon].join(" ")} disabled={disabled}><img src={plus_icon} alt="" /></button>} */}
                          
                      </>
                    )
                  }
                })()
              }
              {
              showCopy && <>
                {/* <input type={inputType} name={fieldName} className={["form-control", styles.border_bottom, styles.form_input].join(" ")} placeholder={label} value={value==null ? "" : value} onChange={(event)=>this.handleInputChange(event,sectionName)} disabled={disabled}/>
                { icon && <button type="button" onClick={()=>{}} className={["btn", "btn-text", "value-button", styles.value_btn, "pl-0", "pr-0"].join(" ")} disabled={disabled}><img src={plus_icon} alt="" /></button>} */}
                <div className="input-group-append">
                  <button className={[styles.copyText, "input-group-text","p-0"].join(' ')} onClick={()=>{this.copyText(value)}} >Copy</button>
                </div> 
                <span className={[styles.bar,extraClassBar,this.state.focused && styles.greenBar].join(" ")}></span> </>
              }
              {
                showBtnFinal && <>
                <div className="input-group-append">
                  {/* <ProceedButton disabled={false} onClick={onBtnClick} loading={false}/> */}
                  <button className={[styles.copyText, "input-group-text","p-0"].join(' ')} onClick={onBtnClick} >{btnText}</button>
                </div>
                <span className={[styles.bar,extraClassBar,this.state.focused && styles.greenBar].join(" ")}></span> </>

              }
            </div>
        </div>
      </>
    )
  }
}

const SkeletonItem = ({height=null,width=null, circle=false}) => {
    return (
    <div style={{width:'100%', height:'100%'}}><Suspense fallback={<div></div>}>
      <SkeletonComponents  height={height} width={width}/>
    </Suspense></div>)
}


export default FormField