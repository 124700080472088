import React from "react"
import { navigate } from "gatsby"
import styles from "./styles.module.css";
import close_profile_icon  from "../../images/close_profile_icon.svg"
import edit_profile_icon  from "../../images/edit_profile_icon.svg"
import PlanSideBar from "../../shared/planSideBar";
import {  getToken, getUser, isUserAdmin } from "../../services/auth";
import { IdentityFieldsForm } from "../identityFieldsForm";
import { editCustomerDetails, viewCustomerProfileDetails, editCorrespondenceEmails } from "../../services/customerInfo";
import { isValidationError, identityValidationFields, emailInvalid } from "../../common/validation";
import ProceedButton from "../proceedButton";
import ProfileSectionHeading from '../../shared/profileSectionHeading';
import plus_icon from "../../images/plus.svg"
import minus_icon from "../../images/minus.svg"
import FormField from "../../shared/formField";


class Profile extends React.Component {
  constructor(props) {
      super(props);
      const user = getUser();
      const isTemp = user && user.id ==='TEMP';

      this.state = {
        identityDisabled: true,
        correspondenceDisabled: true,
        CustomerDetails: {
          FirstName: "",
          LastName: "",
          EmailAddr: this.props.selectedEmail || "",
          InvoiceCompanyName: false,
          CompanyName: "",
          TelCountryCode: "",
          TelNum: "",
          CellCountryCode: "",
          CellNum: "",
          GSTIN: "",
          Address1: "",
          Address2: "",
          City: "",
          StateCode: -999,
          Country: -999,
          ZipCode: "",
        },
        CorrespondenceEmails: [],
        CorrespondenceEmailNew: "",
        IsBillingSame: false,
        BillingDetails: {
          FirstName: "",
          LastName: "",
          EmailAddr: this.props.selectedEmail || "",
          InvoiceCompanyName: false,
          CompanyName: "",
          TelCountryCode: "",
          TelNum: "",
          CellCountryCode: "",
          CellNum: "",
          GSTIN: "",
          Address1: "",
          Address2: "",
          City: "",
          StateCode: -999,
          Country: "",
          ZipCode: ""
        },
        isLoading: {
          updateCustomerInformation: false
        },
        user,
        dirty: false,
        correspondenceDirty: false,
        saveText: 'Saved',
        isTemp,
        isLoaded: true
      }
  }

  componentDidMount() {
    if (this.state.isTemp) {
      navigate('/account/plan');
    } else {
      this.fetchCustomerDetails(getToken());
    }
  }

  onChange = (event, type) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
     
    if (!type) {
        var BillingDetails = this.state.BillingDetails;

        if (target.name === 'IsBillingSame') {
            BillingDetails = value ? this.state.CustomerDetails : {
                FirstName: "",
                LastName: "",
                EmailAddr: "",
                CompanyName: "",
                TelCountryCode: "91",
                TelNum: "",
                CellCountryCode: "91",
                CellNum: "",
                GSTIN: "",
                Address1: "",
                Address2: "",
                City: "",
                StateCode: -999,
                Country: "India",
                ZipCode: "",
                InvoiceCompanyName: false
            }
        }

        this.setState({
          BillingDetails,
          [name]: value
        });
    } else {
        var StateCode = this.state[type].StateCode;
        var Country = this.state[type].Country;

        if (target.name === 'StateCode') {
            Country = value===-1 ? Country : "India";
            StateCode = value;
        } else if (target.name === 'Country') {
            Country = value;
            StateCode = value==="India" ? StateCode : -1;
        }

        this.setState({
            [type]: {
                ...this.state[type],
                [name]: value,
                StateCode,
                Country
            }
        });
    }
  }

  fetchCustomerDetails = (token) => {

    var loadingTimeout = setTimeout(() => {
      this.setState({
        isLoaded: false
      });
    },100)

    viewCustomerProfileDetails(token)
    .then(({error,response}) =>{
      if (error.status<400) {
        clearTimeout(loadingTimeout);
        this.setState({ 
          CustomerDetails: response.CustomerDetails,
          BillingDetails: response.BillingDetails,
          IsBillingSame: response.IsBillingSame,
          CorrespondenceEmails: response.CorrespondenceEmails,
          dirty: false,
          correspondenceDirty: false,
          isLoaded: true 
        }) 
      }
    }).catch(error => {
      console.log("Error:", error);
    });
  }

  updateCustomerInformation = () => {

   
    const customerRequiredError = isValidationError(this.state.CustomerDetails, identityValidationFields);
    const billingRequiredError = !this.state.IsBillingSame && isValidationError(this.state.BillingDetails, identityValidationFields);

    if(customerRequiredError || billingRequiredError) {
        this.setState({dirty: true});
    } else {
      var loadingTimeout = setTimeout(() => {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            updateCustomerInformation: true
          }
        });
      },100)
      var param = {
        CustomerDetails: this.state.CustomerDetails,
        BillingDetails: this.state.BillingDetails,
        IsBillingSame: this.state.IsBillingSame
      }
      this.setState({identityDisabled: true});
      editCustomerDetails(param,getToken())
        .then(({response,error}) => {
          if (error.status >= 400) {
            this.setState({
              saveText: 'Error saving'
            })
          } else {
            this.setState({
              saveText: 'Saved'
            })
          }

          this.fetchCustomerDetails(getToken());

          clearTimeout(loadingTimeout);

          this.setState({
            isLoading: {
              ...this.state.isLoading,
              updateCustomerInformation: false
            }
          });
        })
    }
  }

  updateCorrespondenceEmails = () => {
    var CorrespondenceEmails = this.state.CorrespondenceEmails;
    var body = {
      CorrespondenceEmails
    };

    editCorrespondenceEmails(getToken(),body).then(({error,response}) => {
      this.setState({correspondenceDisabled: true});
      this.fetchCustomerDetails(getToken());
    })
  }

  addCorrespondenceEmail = () => {
    if (this.state.CorrespondenceEmailNew && this.state.CorrespondenceEmailNew.length>0) {
      var isEmailInvalid = emailInvalid(this.state.CorrespondenceEmailNew,false);
      if (isEmailInvalid) {
        this.setState({correspondenceDirty: true});
      } else {
        var CorrespondenceEmails = [...this.state.CorrespondenceEmails,this.state.CorrespondenceEmailNew];
        this.setState({CorrespondenceEmails,correspondenceDirty:true});
        setTimeout(() => {
          this.setState({CorrespondenceEmailNew:'',correspondenceDirty:false})
        })
      }
    }
  }

  toggleEditIdentityFields = () => {
    if (this.state.identityDisabled) {
      this.setState({correspondenceDisabled:true, identityDisabled: false, saveText: 'Save'});
    } else {
      this.fetchCustomerDetails(getToken());
      this.setState({correspondenceDisabled: true, identityDisabled: true, correspondenceDirty: false, dirty: false, saveText: 'Save'});
    }
  }

  toggleEditCorrespondenceFields = () => {
    if (this.state.correspondenceDisabled) {
      this.setState({correspondenceDisabled:false, identityDisabled: true, saveText: 'Save'});
    } else {
      this.fetchCustomerDetails(getToken());
      this.setState({correspondenceDisabled: true, identityDisabled: true, correspondenceDirty: false, dirty: false, saveText: 'Save'});
    }
  }
  
  deleteEmail = (idx) => {
    this.setState({
      CorrespondenceEmails: this.state.CorrespondenceEmails.filter((s, sidx) => idx !== sidx)
    });
  }

  render() {
    const isAdmin = isUserAdmin(this.state.user.access_rights);
    const icon = isAdmin ? (this.state.identityDisabled ? edit_profile_icon : close_profile_icon) : null;
    const correspondece_icon = isAdmin ? (this.state.correspondenceDisabled ? edit_profile_icon : close_profile_icon) : null;
    return (
        <>
          <div className={styles.plan_section}>
            <div className="container-fluid">
                <div className="row plan-grey-background">
                  <PlanSideBar page={"Profile"} />
                    <div className="col-lg-10 col-md-12 p-0 right_section">
                      <div className="col-lg-12 col-md-12">
                          <div className={["mpr_plan_section" , "p-lg-5", "p-md-3", styles.mpr_profile_section ].join( ' ' )}>
                            <div className={["mpr_c_plan_title", "mt-5", "pb-5"].join(" ")}>
                              <h2>My Profile</h2>
                            </div>
                          
                            <ProfileSectionHeading text={'Personal Information'} icon={icon} onClick={this.toggleEditIdentityFields} noMarginTop={true}/>
                            <div className={["card", "mpr-card-shadow", "p-4", "mb-md-5", "mb-3"].join(" ")}>
                              <IdentityFieldsForm sectionValue={this.state} sectionName={'CustomerDetails'} handleInputChange={(e)=>{this.onChange(e,'CustomerDetails')}} disabled={this.state.identityDisabled} emailDisabled={true} isLoaded={this.state.isLoaded}/>
                            </div>

                            <ProfileSectionHeading text={'Billing Information'} icon={icon} onClick={this.toggleEditIdentityFields}/>
                            <div className={["card", "mpr-card-shadow", "p-4", "mb-md-5", "mb-3"].join(" ")}>
                                <div className="row">
                                  <div className={["custom-checkbox", "col-md-12", "mb-4"].join(" ")}>    
                                      <input className={["custom-input", "mr-2", this.state.identityDisabled ? "disabled" : ""].join(" ")} id="IsBillingSame" disabled={this.state.identityDisabled} name="IsBillingSame" type="checkbox" checked={this.state.IsBillingSame} onChange={(e) => {this.onChange(e,null)}} />
                                      <label className={[styles.customLabel,this.state.identityDisabled ? styles.disabled : ''].join(" ")} htmlFor="IsBillingSame">Same as Personal Address</label>
                                  </div>
                                </div>
                                {
                                  !this.state.IsBillingSame &&
                                  <IdentityFieldsForm sectionValue={this.state} sectionName={'BillingDetails'} handleInputChange={(e)=>{this.onChange(e,'BillingDetails')}} disabled={this.state.identityDisabled} isLoaded={this.state.isLoaded} dirty={this.state.dirty}/>
                                }
                                
                            </div>

                            <ProfileSectionHeading text={'Correspondence Emails'} icon={correspondece_icon} onClick={this.toggleEditCorrespondenceFields} showTooltip={true} tooltipText={'You can add additional emails to receive correspondence about your MProfit account.'} tooltipName={'tooltip-correspondence'}/>
                            <div className={["card", "mpr-card-shadow", "p-4", "mb-md-5", "mb-3"].join(" ")}>
                              <div className="list-group">
                                <div>
                                    {this.state.CorrespondenceEmails.map((email,idx)=>
                                      <div className={["d-inline-block", styles.email_list, "mb-3"].join(" ")} key={idx}>
                                        <div className="d-flex align-items-center p-2 list-group-item">
                                          <span className="badge badge-pill p-0 mr-3">
                                            <button type="button" onClick={()=>{this.deleteEmail(idx)}} className={["btn p-0", "btn-text", "plus-minus-btn", "pl-0", "pr-0"].join(" ")} disabled={this.state.correspondenceDisabled}><img src={minus_icon} alt="" /></button>
                                          </span>
                                          {email}
                                        </div>
                                      </div>
                                    )}
                                    {
                                      this.state.CorrespondenceEmails.length===0 && this.state.correspondenceDisabled &&
                                      <div className="col-12 mb-3">
                                        You can add additional emails to receive correspondence about your MProfit account.
                                      </div>
                                    }
                                </div>
                              </div>
                              {
                                !this.state.correspondenceDisabled &&
                                <div className="row">
                                  <FormField fieldName="CorrespondenceEmailNew" label="Add new correspondence email" sectionName={null} sectionValue={this.state} handleInputChange={this.onChange} perLine={2} inputType={'email'} customValidation={'email'} dirty={this.state.correspondenceDirty} icon={plus_icon} onBtnClick={this.addCorrespondenceEmail} onEnter={this.addCorrespondenceEmail} errorDuringFocus={false}/>
                                </div>
                              }
                            </div>

                              
                          </div>
                      </div>
                      {
                        !(this.state.identityDisabled && this.state.correspondenceDisabled) && isAdmin &&
                        <div className={["fixed-bottom","position-sticky",styles.fixed_save_btn,"d-flex","flex-column","justify-content-center"].join(' ')}>
                          <div className="row">
                            <div className="col-lg-2">
                              <ProceedButton buttonText={this.state.saveText} btnClasses={[styles.btn_save,"btn", "btn-block", "btn-primary", "blue_btn"].join(' ')} onClick={this.state.identityDisabled ? this.updateCorrespondenceEmails : this.updateCustomerInformation} borderRadius={3} loading={this.state.isLoading.updateCustomerInformation} disabled={!this.state.correspondenceDisabled && this.state.CorrespondenceEmailNew !== ''}/>
                            </div>
                            {
                              this.state.dirty &&
                              <div className="col-lg-6 d-flex align-items-center">
                                <span className={styles.validationErrorText}>There are one or more errors in the fields above. Please correct the details to save changes.</span>
                              </div>
                            }
                          </div>
                        </div>
                      }
                    </div>
                </div>
            </div>
          </div>
        </>
      )
    }
}


export default Profile