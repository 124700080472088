
export function phoneNumberpattern(value){
 return (value.replace(/[' ',-/]/g, "")).length === 10 ? "confirm" : true;
}

export function required(value){
    return value && value.length > 0 ? false : true;
}

export function requiredError(value, inputType){
    if (inputType === 'dropdown' && (typeof value === 'number')) {
        return value < -1;
    } else if (inputType === 'number') {
        return value === null || value === undefined;
    } else {
        return !(value && value.length>0)
    }
}

export function emailValid(value){
    var emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    return emailValid ? "confirm" : true;
}

export function emailInvalid(value,optional=false){
    if (optional && (!value || value.length === 0)) {
        return false;
    } else if (!value) {
        return true;
    }
    var emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    return emailValid ? false : true;
}

export function phoneNumberInvalid(value,optional,countryCodeValue) {
    if (optional && (!value || value.length === 0)) {
        return false;
    } else if (!value) {
        return true;
    }

    var strippedValue = value.replace(/[-.\s]/g, '');
    var phoneValid = ((""+countryCodeValue) === "91" ? (/^\d{10}$/) : (/^\d{6,15}$/)).test(strippedValue);

    return phoneValid ? false : true;
}

export function validation(type, value, prevValue){
    switch (type){
        case "tel":
            return phonenumberValid(value, prevValue)
        default:
            return value;
    }
}

export function isValidationError(state, validationFields) {
    for (var field of validationFields) {
        if (field.name == 'StateCode' && state[field.name] == -1) {
            if (state['Country'] == 'India') {
                return true;
            }
        }

        if (!field.optional && requiredError(state[field.name],field.type)) {
            return true;
        } else if (field.validation==='telno' && phoneNumberInvalid(state[field.name],field.optional,state[field.countryCodeFieldName])) {
            return true;
        } else if (field.validation==='email' && emailInvalid(state[field.name],field.optional)) {
            return true
        }
    };

    return false;
}

function phonenumberValid(value, prevValue)
{
   var phoneValidation = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-()\s\./0-9]*$/g;
   
  if(value.match(phoneValidation)) {
    return value;
  }
  else {
      if(value.length > 0)
        return prevValue;
        else
        return ""
  }
   
}

export const identityValidationFields = [
    { name: 'FirstName', type: 'text' },
    { name: 'LastName', type: 'text' },
    { name: 'CellNum', type: 'text', validation: 'telno' },
    { name: 'EmailAddr', type: 'text', validation: 'email' },
    { name: 'Address1', type: 'text' },
    { name: 'City', type: 'text' },
    { name: 'ZipCode', type: 'text' },
    { name: 'StateCode', type: 'dropdown' },
    { name: 'Country', type: 'text' },
    { name: 'TelNum', type: 'text', validation: 'telno', optional: true },
]