import React from "react"
import CountryCode from "../../shared/CountryCode";
import FormField from "../formField";

class PhoneField extends React.Component {

  render() {
    const {countryCodeFieldName='TelCountryCode', numFieldName='TelNum', countryCodeLabel="Country", numLabel="Tel Number", sectionName, sectionValue, handleInputChange, perLine=2, optional=false, disabled, dirty, mobilePerLine=2, isLoaded,  hideOptionalText=false} = this.props;
    var size, msize;
    switch(perLine) {
      case 1:
        size = 12;
        break;
      default:
      case 2:
        size = 6;
        break;
    }
    

    return (
      <>
        <div className={["col-md-"+size].join(" ")}>
          <div className={["row", "m-0"].join(" ")}>
            <FormField fieldName={countryCodeFieldName} label={countryCodeLabel} sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} inputType="dropdown" options={CountryCode} optional={optional} hideOptionalText={true} disabled={disabled} dirty={dirty} sizeOverride={4} msize={3} extraClass={"p-0"} isLoaded={isLoaded}/>
            <FormField fieldName={numFieldName} label={numLabel} sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} disabled={disabled} optional={optional} dirty={dirty} sizeOverride={8} extraClass={"p-0 pl-3"} customValidation={'phone'}  msize={ 9 } isLoaded={isLoaded} hideOptionalText={hideOptionalText}/>
          </div>
        </div>
      </>
    )
  }
}

export default PhoneField