import React from "react"
import FormField from "../../shared/formField";
import PhoneField from "../../shared/phoneField";
import { StateList, CountryList } from "../../common/dropdowns";

export const IdentityFieldsForm = ({sectionValue, sectionName, handleInputChange, disabled=false, emailDisabled=false, dirty, manageEmail, isLoaded=true}) => {
    return (
        <>
            <div className="row">
                <FormField fieldName="FirstName" label="First Name" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>
                <FormField fieldName="LastName" label="Last Name" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>
            </div>
            <div className="row">
                <FormField fieldName="CompanyName" label="Company Name" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} sizeOverride={6} optional={true} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>
                <FormField fieldName="InvoiceCompanyName" label="Invoice in Company Name?" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} sizeOverride={6} optional={true} disabled={disabled} dirty={dirty} inputType={'checkbox'} isLoaded={isLoaded}/>
            </div>
            <div className="row">
                <FormField fieldName="GSTIN" label="GSTIN" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} perLine={1} optional={true} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>
            </div>
            <div className="row">
                <FormField fieldName="EmailAddr" label="Email" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} perLine={1} inputType={'email'} disabled={emailDisabled || disabled} p dirty={dirty} errorDuringFocus={false} isLoaded={isLoaded}/>
            </div>
            <div className="row">
                <PhoneField countryCodeFieldName='CellCountryCode' numFieldName='CellNum' countryCodeLabel="Country" numLabel="Cell Number" sectionName={sectionName} sectionValue={sectionValue} mobilePerLine={3} handleInputChange={handleInputChange} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>
                <PhoneField sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} optional={true} disabled={disabled} dirty={dirty} mobilePerLine={3} isLoaded={isLoaded}/>
            </div>
            <div className="row">
                <FormField fieldName="Address1" label="Address 1" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} perLine={1} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>
            </div>
            <div className="row">
                <FormField fieldName="Address2" label="Address 2" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} perLine={1} optional={true} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>        
            </div>
            <div className="row">
                <FormField fieldName="City" label="City" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>        
                <FormField fieldName="ZipCode" label="Zipcode" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>
            </div>
            <div className="row">
                <FormField fieldName="StateCode" label="State" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} inputType="dropdown" options={StateList} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>
                <FormField fieldName="Country" label="Country" sectionName={sectionName} sectionValue={sectionValue} handleInputChange={handleInputChange} inputType="dropdown" options={CountryList} disabled={disabled} dirty={dirty} isLoaded={isLoaded}/>
            </div>
        </>
    )
}