const CountryCode = [
  { id: "1", text: "1" },
  { id: "20", text: "20" },
  { id: "210", text: "210" },
  { id: "211", text: "211" },
  { id: "212", text: "212" },
  { id: "213", text: "213" },
  { id: "214", text: "214" },
  { id: "215", text: "215" },
  { id: "216", text: "216" },
  { id: "217", text: "217" },
  { id: "218", text: "218" },
  { id: "219", text: "219" },
  { id: "220", text: "220" },
  { id: "221", text: "221" },
  { id: "222", text: "222" },
  { id: "223", text: "223" },
  { id: "224", text: "224" },
  { id: "225", text: "225" },
  { id: "226", text: "226" },
  { id: "227", text: "227" },
  { id: "228", text: "228" },
  { id: "229", text: "229" },
  { id: "230", text: "230" },
  { id: "231", text: "231" },
  { id: "232", text: "232" },
  { id: "233", text: "233" },
  { id: "234", text: "234" },
  { id: "235", text: "235" },
  { id: "236", text: "236" },
  { id: "237", text: "237" },
  { id: "238", text: "238" },
  { id: "239", text: "239" },
  { id: "240", text: "240" },
  { id: "241", text: "241" },
  { id: "242", text: "242" },
  { id: "243", text: "243" },
  { id: "244", text: "244" },
  { id: "245", text: "245" },
  { id: "246 ", text: "246 " },
  { id: "247", text: "247" },
  { id: "248", text: "248" },
  { id: "249 ", text: "249 " },
  { id: "250", text: "250" },
  { id: "251", text: "251" },
  { id: "252", text: "252" },
  { id: "253", text: "253" },
  { id: "254 ", text: "254 " },
  { id: "255", text: "255" },
  { id: "256", text: "256" },
  { id: "257", text: "257" },
  { id: "258", text: "258" },
  { id: "259", text: "259" },
  { id: "260", text: "260" },
  { id: "261", text: "261" },
  { id: "262 ", text: "262 " },
  { id: "263", text: "263" },
  { id: "264", text: "264" },
  { id: "265 ", text: "265 " },
  { id: "266", text: "266" },
  { id: "267", text: "267" },
  { id: "268", text: "268" },
  { id: "269", text: "269" },
  { id: "27", text: "27" },
  { id: "28 ", text: "28 " },
  { id: "290", text: "290" },
  { id: "291", text: "291" },
  { id: "292", text: "292" },
  { id: "293", text: "293" },
  { id: "294", text: "294" },
  { id: "295", text: "295" },
  { id: "296", text: "296" },
  { id: "297", text: "297" },
  { id: "298", text: "298" },
  { id: "299", text: "299" },
  { id: "30", text: "30" },
  { id: "31", text: "31" },
  { id: "32", text: "32" },
  { id: "33", text: "33" },
  { id: "34", text: "34" },
  { id: "350", text: "350" },
  { id: "351", text: "351" },
  { id: "352", text: "352" },
  { id: "353", text: "353" },
  { id: "354", text: "354" },
  { id: "355", text: "355" },
  { id: "356", text: "356" },
  { id: "357", text: "357" },
  { id: "358", text: "358" },
  { id: "359 ", text: "359 " },
  { id: "36", text: "36" },
  { id: "370", text: "370" },
  { id: "371", text: "371" },
  { id: "373", text: "373" },
  { id: "374", text: "374" },
  { id: "375", text: "375" },
  { id: "377", text: "377" },
  { id: "38 ", text: "38 " },
  { id: "380", text: "380" },
  { id: "381", text: "381" },
  { id: "385 ", text: "385 " },
  { id: "387", text: "387" },
  { id: "389 ", text: "389 " },
  { id: "39", text: "39" },
  { id: "66982,", text: "66982," },
  { id: "40", text: "40" },
  { id: "41", text: "41" },
  { id: "42", text: "42" },
  { id: "43", text: "43" },
  { id: "44", text: "44" },
  { id: "45", text: "45" },
  { id: "46", text: "46" },
  { id: "47", text: "47" },
  { id: "48", text: "48" },
  { id: "49", text: "49" },
  { id: "500 ", text: "500 " },
  { id: "501", text: "501" },
  { id: "502", text: "502" },
  { id: "503", text: "503" },
  { id: "504", text: "504" },
  { id: "505", text: "505" },
  { id: "506", text: "506" },
  { id: "507", text: "507" },
  { id: "508", text: "508" },
  { id: "509", text: "509" },
  { id: "51", text: "51" },
  { id: "52", text: "52" },
  { id: "53", text: "53" },
  { id: "54", text: "54" },
  { id: "55", text: "55" },
  { id: "56", text: "56" },
  { id: "57", text: "57" },
  { id: "58", text: "58" },
  { id: "590 ", text: "590 " },
  { id: "591", text: "591" },
  { id: "592", text: "592" },
  { id: "593", text: "593" },
  { id: "594", text: "594" },
  { id: "595", text: "595" },
  { id: "596 ", text: "596 " },
  { id: "597", text: "597" },
  { id: "598", text: "598" },
  { id: "599", text: "599" },
  { id: "60", text: "60" },
  { id: "61", text: "61" },
  { id: "62", text: "62" },
  { id: "63", text: "63" },
  { id: "64 ", text: "64 " },
  { id: "65", text: "65" },
  { id: "66", text: "66" },
  { id: "670", text: "670" },
  { id: "671 ", text: "671 " },
  { id: "672", text: "672" },
  { id: "673 ", text: "673 " },
  { id: "674", text: "674" },
  { id: "675", text: "675" },
  { id: "676", text: "676" },
  { id: "677", text: "677" },
  { id: "678", text: "678" },
  { id: "679 ", text: "679 " },
  { id: "680", text: "680" },
  { id: "681", text: "681" },
  { id: "682 ", text: "682 " },
  { id: "683", text: "683" },
  { id: "684 ", text: "684 " },
  { id: "685 ", text: "685 " },
  { id: "686", text: "686" },
  { id: "687", text: "687" },
  { id: "688", text: "688" },
  { id: "689", text: "689" },
  { id: "690", text: "690" },
  { id: "691", text: "691" },
  { id: "692", text: "692" },
  { id: "7 ", text: "7 " },
  { id: "808", text: "808" },
  { id: "809", text: "809" },
  { id: "81", text: "81" },
  { id: "82", text: "82" },
  { id: "84 ", text: "84 " },
  { id: "850", text: "850" },
  { id: "852 ", text: "852 " },
  { id: "853", text: "853" },
  { id: "855", text: "855" },
  { id: "856 ", text: "856 " },
  { id: "86", text: "86" },
  { id: "871 ", text: "871 " },
  { id: "872", text: "872" },
  { id: "873", text: "873" },
  { id: "874", text: "874" },
  { id: "880", text: "880" },
  { id: "886", text: "886" },
  { id: "90 ", text: "90 " },
  { id: "91", text: "91" },
  { id: "92 ", text: "92 " },
  { id: "93", text: "93" },
  { id: "94", text: "94" },
  { id: "95 ", text: "95 " },
  { id: "960", text: "960" },
  { id: "961", text: "961" },
  { id: "962 ", text: "962 " },
  { id: "963", text: "963" },
  { id: "964", text: "964" },
  { id: "965 ", text: "965 " },
  { id: "966", text: "966" },
  { id: "967", text: "967" },
  { id: "968", text: "968" },
  { id: "969", text: "969" },
  { id: "971", text: "971" },
  { id: "972 ", text: "972 " },
  { id: "973", text: "973" },
  { id: "974", text: "974" },
  { id: "975 ", text: "975 " },
  { id: "976", text: "976" },
  { id: "977", text: "977" },
  { id: "98", text: "98" },
  { id: "993", text: "993" },
  { id: "994", text: "994" },
  { id: "995", text: "995" },
]

export default CountryCode
